<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >

    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="itemId || itemData.id"
            class="mb-0"
          > <span v-t="`modules.customers.pages.edit`" />: {{ itemData.name || itemId }}</h5>
          <h5
            v-else
            v-t="`modules.customers.pages.add`"
            class="mb-0"
          />

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div>
              <i-input
                id="firstName"
                v-model="itemData.first_name"
                :errors="validationErrors.first_name"
                type="text"
                title="inputs.first_name"
                required
              />
            </div>

            <div>
              <i-input
                id="lastName"
                v-model="itemData.last_name"
                :errors="validationErrors.last_name"
                type="text"
                title="inputs.last_name"
                required
              />
            </div>

            <div>
              <i-input
                id="email"
                v-model="itemData.email"
                :errors="validationErrors.email"
                type="email"
                title="inputs.email"
                required
              />
            </div>

            <div>
              <i-input
                id="mobile"
                v-model="itemData.mobile"
                :errors="validationErrors.mobile"
                type="tel"
                title="inputs.mobile"
                :dial-code="itemData.mobile_code"
                @update:dial-code="val => itemData.mobile_code = val"
              />
            </div>

            <div>

              <i-input
                v-model="itemData.country_code"
                :errors="validationErrors.country_code"
                type="select"
                title="inputs.country"
                :options="countryOptions"
                :clearable="true"
              />
            </div>

            <div>
              <i-input
                v-model="itemData.is_active"
                :errors="validationErrors.is_active"
                type="switch"
                title="inputs.is_active"
                description="inputs.is_active:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import customerStoreModule from '@/views/models/customers/customerStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
  },
  emits: ['refetch-data'],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-category',
      required: false,
    },
    itemId: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        // if (!this.itemId) {
        this.resetForm()
        // }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'customers'

    // Register module
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, customerStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      mobile_code: null,
      mobile: null,
      country_code: null,
      is_active: true,
    }
    const itemData = ref({})
    const isLoading = ref(true)

    const countryOptions = ref([])

    store.dispatch('customers/fetchCountries')
      .then(response => { countryOptions.value = response.data.data })

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(props.value || emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)
      },
      null,
      null,
      () => {
        resetItemData()

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const fetchItem = () => {
      if (itemData.value.id !== props.itemId) {
        itemData.value.id = props.itemId

        isLoading.value = true
        return fetch().finally(() => {
          isLoading.value = false
        })
      }
      return null
    }

    const onSubmit = () => {
      isLoading.value = true
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    const handleEvent = arg => {
      itemData.value.mobile_code = arg
      //console.log('Handle EVENT', arg, itemData.value.mobile_code)
      // TODO: Check why first not counted in
    }

    return {
      itemData,
      fetchItem,
      validationErrors,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetItemData,
      resetForm,

      refs,
      isLoading,
      countryOptions,

      handleEvent,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vti__dropdown-list {
  z-index: 2;
}
</style>
